import { ReactElement, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlotInfo } from "react-big-calendar";
import { SxProps } from "@mui/material";
import {
  actions, CalDateRange, DisplayCalendarsDict, GlobalModalComponentName, Leader, MeetingSlot,
  RootState, ThunkDispatchType,
} from "../../../store";
import CalendarScheduler from "./CalendarScheduler";
import { PAGE_URL } from "../../../constants";
import { selectUserCalendarEvents } from "../../../store/schedule/selectors";
import { TimeZone } from "../../../utils/scheduleUtils";
import { router } from "../../../router";


type Props =  {
  selectedLeaders: Leader['id'][];
  leaderHasAssociations: boolean;
  userHasGrant: boolean;
  displayCalendars: DisplayCalendarsDict;
  onCalendarClick?: (calendarId: string) => void;
  openAdditionalCalendarsModal?: () => void;
  currentDateRangeInfo: CalDateRange | null;
  loadingCalendars: boolean;
  handleOpenMeeting?: (meetingId: number) => void;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleSlotsCreated?: (info: SlotInfo, isExcluded?: boolean) => void;
  handleEditSlot?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDateChange: (range: CalDateRange) => void;
  calendarTimezoneSelected: TimeZone | undefined;
  handleCalendarTimezoneSelected?: (timezone: TimeZone) => void;
  secondaryTimezonesSelected: (TimeZone | undefined)[];
  handleSecondaryTimezoneSelected?: (idx: number, timezone: TimeZone) => void;
  handleDeleteSlots?: (slots: MeetingSlot[]) => void;
  currentMeetingId: number | undefined;
  isPoll?: boolean
  handleDuplicateMeeting?: (meetingId: number) => void
  handleDeleteMeeting?: (meetingId: number) => void
  handleShareMeeting?: (meetingId: number) => void
  onToggleShowAllRecurringSlots?: () => void;
  showAllRecurringSlots?: boolean;
  showCanceledDeclinedMeetings: boolean;
  onToggleCanceledDeclinedMeetings?: () => void;
  additionalCalendarIds: number[];
  onUpdateAdditionalCalendar?: (additionalCalendars: number[]) => void
  numHiddenCalendarTeammates?: number;
  sx?: SxProps;
};

const CalendarSchedulerContainer = ({
  selectedLeaders, leaderHasAssociations, userHasGrant, displayCalendars, onCalendarClick,
  openAdditionalCalendarsModal, currentDateRangeInfo, loadingCalendars, handleOpenMeeting, selectedSlots,
  recurringSlots, handleSlotsCreated, handleEditSlot, onDateChange, calendarTimezoneSelected,
  handleCalendarTimezoneSelected, secondaryTimezonesSelected, handleSecondaryTimezoneSelected, handleDeleteSlots,
  currentMeetingId, isPoll, handleDuplicateMeeting, handleDeleteMeeting, handleShareMeeting,
  onToggleShowAllRecurringSlots, showAllRecurringSlots, showCanceledDeclinedMeetings, onToggleCanceledDeclinedMeetings,
  additionalCalendarIds, onUpdateAdditionalCalendar, numHiddenCalendarTeammates, sx,
}: Props): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = router.navigate;

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const newMeeting = useSelector((state: RootState) => state.schedule.newMeeting);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);

  const visibleDisplayCalendars = useMemo(() => (
    Object.values(displayCalendars).filter(c => c.display).map(c => c.calendarId)
  ), [displayCalendars]);

  const consolidatedEvents = useSelector((state: RootState) => selectUserCalendarEvents(
    state,
    new Set(visibleDisplayCalendars),
    currentDateRangeInfo?.startStr,
    currentDateRangeInfo?.endStr,
    showCanceledDeclinedMeetings,
  ));

  const currentMeeting = currentMeetingId ? meetings[currentMeetingId] : null;
  const currentOrNewMeeting = currentMeeting || newMeeting;
  const showSecondaryTimezone = user?.features.MULTI_TIMEZONE && schedulingPrefs.user_prefs?.multi_timezone;
  const currentMeetingDurationMinutes = currentOrNewMeeting ? currentOrNewMeeting.duration_minutes : undefined;
  const meetingPreventDoubleBooking = currentOrNewMeeting?.prevent_conflict;

  const openUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const openAssociationModal = () => navigate(PAGE_URL.MANAGE_CALENDARS);
  const openAccountManagementModal = () => navigate(PAGE_URL.INTEGRATION_SETTINGS);
  const handleOpenPollResults = (handleOpenPollResultsId: number) => (
    navigate(`${PAGE_URL.POLL_RESULTS}/${handleOpenPollResultsId}/`)
  );

  const handleToggleMultiTimezone = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        multi_timezone: !schedulingPrefs.user_prefs?.multi_timezone,
      }));
    }
  };

  return (
    <CalendarScheduler
      selectedLeaders={selectedLeaders.map(lId => leaders.leaders.find(l => l.id === lId))
        .filter((leader): leader is Leader => !!leader)}
      calendars={calendars}
      meetings={meetings}
      newMeeting={newMeeting}
      coloredEvents={consolidatedEvents}
      showSecondaryTimezone={showSecondaryTimezone}
      currentMeetingDurationMinutes={currentMeetingDurationMinutes}
      meetingPreventDoubleBooking={meetingPreventDoubleBooking}
      hasMultiTimezone={user?.features.MULTI_TIMEZONE}
      hasMultiLeaderCalendarView={user?.features.MULTI_LEADER_CALENDAR_VIEW}
      onToggleMultiTimezone={handleToggleMultiTimezone}
      leaderHasAssociations={leaderHasAssociations}
      userHasGrant={userHasGrant}
      displayCalendars={displayCalendars}
      onCalendarClick={onCalendarClick}
      openAssociationModal={openAssociationModal}
      openAdditionalCalendarsModal={openAdditionalCalendarsModal}
      openAccountManagementModal={openAccountManagementModal}
      currentDateRangeInfo={currentDateRangeInfo}
      loadingCalendars={loadingCalendars}
      handleOpenMeeting={handleOpenMeeting}
      selectedSlots={selectedSlots}
      recurringSlots={recurringSlots}
      handleSlotsCreated={handleSlotsCreated}
      handleEditSlot={handleEditSlot}
      onDateChange={onDateChange}
      calendarTimezoneSelected={calendarTimezoneSelected}
      handleCalendarTimezoneSelected={handleCalendarTimezoneSelected}
      secondaryTimezonesSelected={secondaryTimezonesSelected}
      handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
      handleDeleteSlots={handleDeleteSlots}
      currentMeetingId={currentMeetingId}
      isPoll={isPoll}
      handleDuplicateMeeting={handleDuplicateMeeting}
      handleDeleteMeeting={handleDeleteMeeting}
      handleShareMeeting={handleShareMeeting}
      onOpenPollResults={handleOpenPollResults}
      onToggleShowAllRecurringSlots={onToggleShowAllRecurringSlots}
      showAllRecurringSlots={showAllRecurringSlots}
      showCanceledDeclinedMeetings={showCanceledDeclinedMeetings}
      onToggleCanceledDeclinedMeetings={onToggleCanceledDeclinedMeetings}
      openUpgradeModal={openUpgradeModal}
      additionalCalendarIds={additionalCalendarIds}
      onUpdateAdditionalCalendar={onUpdateAdditionalCalendar}
      numHiddenCalendarTeammates={numHiddenCalendarTeammates}
      sx={sx}
    />
  );
};

export default memo(CalendarSchedulerContainer);
